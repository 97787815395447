import React, { useState, useEffect } from 'react';
import Interactive from '../interactive/interactive.component';

function Interactives() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('/json/data.json')
        .then((response) => response.json())
        .then((data) => setData(data));
    }, [])
  
    return (
        <section className='flex justify-between'>
        {data.map((interactive) => {
            return <Interactive key={interactive.id} data={interactive}/>;
        })}
        </section>
    );
    
}

export default Interactives;
    