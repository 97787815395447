function Interactive({data}) {
    const { id, name, description, link_address} = data;
    return ( 
        <div key={id} className="w-60">
            <h3 className="text-heading3 font-medium pb-1">{name}</h3>
            <p className="text-subheading pb-1">{description}</p>
            <a className="text-intro_paragraph font-bold text-light-blue" href={link_address} target="_blank" rel="noopener noreferrer">View Demo</a>
        </div>
     );
}

export default Interactive;