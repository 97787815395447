//import './App.css';
import Header from './components/header/header.component';
import Welcome from './components/welcome/welcome.component';
import Interactives from './components/interactive-group/interactives.component';

function App() {
  return (
    
    <div className="m-auto w-100 xl:w-4/5 2xl:w-3/5 px-3">
      <Header/>
      <Welcome/>
      <h1 className='font-Bonheur text-heading1 text-center mt-14 mb-6'>Portfolio</h1>
      <div className='flex justify-center'>
        <svg className="w-72px" height="69" viewBox="0 0 72 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5876 12.3666C17.1935 13.3493 27.3128 18.1246 30.9433 29.3641L12.5876 12.3666Z" fill="#044BD9" stroke="#134995"/>
            <path d="M29.3675 30.9377C24.7895 29.8328 14.8008 24.7899 11.4707 13.4577L29.3675 30.9377Z" fill="#044BD9" stroke="#134995"/>
            <path d="M35.4771 2.08896C38.0391 6.04069 41.8179 16.5728 36.4375 27.0875L35.4771 2.08896Z" fill="#044BD9" stroke="#134995"/>
            <path d="M34.6962 27.5883C32.6074 23.7061 29.633 13.4377 34.4455 3.42155L34.6962 27.5883Z" fill="#044BD9" stroke="#134995"/>
            <path d="M35.1598 66.2873C32.5979 62.3356 28.8191 51.8035 34.1995 41.2888L35.1598 66.2873Z" fill="#044BD9" stroke="#134995"/>
            <path d="M36.1178 40.3825C39.2509 44.4931 43.7125 55.3655 36.4937 65.9708L36.1178 40.3825Z" fill="#044BD9" stroke="#134995"/>
            <path d="M2.02944 34.0173C6.02616 31.5261 16.6239 27.9354 27.0411 33.5022L2.02944 34.0173Z" fill="#044BD9" stroke="#134995"/>
            <path d="M27.9261 36.1222C23.8645 38.5061 13.175 41.8134 2.90963 35.9714L27.9261 36.1222Z" fill="#044BD9" stroke="#134995"/>
            <path d="M11.6998 56.5961C12.7644 52.0085 17.7192 41.9758 29.0215 38.5461L11.6998 56.5961Z" fill="#044BD9" stroke="#134995"/>
            <path d="M31.877 38.8627C30.6907 43.4204 25.4707 53.3177 14.081 56.4454L31.877 38.8627Z" fill="#044BD9" stroke="#134995"/>
            <path d="M58.1688 56.0984C53.538 55.2406 43.2932 50.7407 39.3601 39.6035L58.1688 56.0984Z" fill="#044BD9" stroke="#134995"/>
            <path d="M40.8933 38.0226C45.4996 39.0033 55.621 43.7743 59.2563 55.0122L40.8933 38.0226Z" fill="#044BD9" stroke="#134995"/>
            <path d="M69.4246 34.17C65.4967 36.7684 55 40.6443 44.436 35.3613L69.4246 34.17Z" fill="#044BD9" stroke="#134995"/>
            <path d="M43.5152 32.7658C47.5109 30.2729 58.1071 26.6777 68.5266 32.24L43.5152 32.7658Z" fill="#044BD9" stroke="#134995"/>
            <path d="M57.8605 11.8958C56.9204 16.5105 52.2388 26.6736 41.0333 30.4077L57.8605 11.8958Z" fill="#044BD9" stroke="#134995"/>
            <path d="M38.1699 30.1336C39.2325 25.5455 44.1829 15.5107 55.4838 12.076L38.1699 30.1336Z" fill="#044BD9" stroke="#134995"/>
            <circle cx="35.407" cy="33.9854" r="3.05393" fill="#251E5C" stroke="#251E5C"/>
        </svg>
      </div>
      <h2 className='font-Julius text-heading2 text-center mt-6 mb-12'>Learning Interactives</h2>
      <Interactives/>
    </div>
  );
}

export default App;
