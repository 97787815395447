
import resume from '../../resume/Diana_Sevillano_deLazaro_Resume.pdf';

function Navbar() {
    return (  
        <ul className='flex font-Julius text-heading2'>
            <li className='pr-3'><a href={resume} rel="noreferrer" target='_blank'>resume</a></li>
            <li className='pr-3'><a href="https://github.com/dianab85" rel="noreferrer" target='_blank' >github</a></li>
        </ul>
    );
}

export default Navbar;

