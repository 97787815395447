import Navbar from '../navbar/navbar.component';

function Header() {
    return ( 
    
     <header className='flex justify-between items-center pt-2'>
        <div className='flex items-center' >
            <h1 className='font-Bonheur text-heading1 pr-6 mt-[-6px]'>Diana</h1>
            <h2 className='font-Julius text-heading2'>Web Developer</h2>
        </div>
         <Navbar/> 
      </header> 
      );
}

export default Header;


