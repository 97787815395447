
function Welcome() {
    return ( 
        <section className="flex items-start mt-8">
            <div className="h-80 px-20">
                <svg className="h-80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M77.8256 321.105C66.3018 255.75 21.8069 242.815 1 244.517C26.6085 237.71 78.7859 210.819 82.6272 157.718C84.2278 183.247 101.834 235.327 159.453 239.412C97.9923 247.581 79.4261 297.278 77.8256 321.105Z" fill="#251E5C" stroke="#251E5C"/>
                    <path d="M141.164 211.069C126.082 127.041 67.8458 110.411 40.6132 112.599C74.1303 103.846 142.421 69.2724 147.449 1C149.544 33.8233 172.587 100.783 248 106.034C167.559 116.538 143.259 180.434 141.164 211.069Z" fill="#12489B" stroke="#12489B"/>
                </svg>
            </div>

            <article className="pt-16 px-5">
                <p className="font-Encode font-normal text-base/[28px] tracking-wide">Experienced Web Developer with 8+ years of expertise in building responsive, user-friendly web applications. Adept at writing well-designed, testable, and efficient code using modern HTML, CSS, PHP, and JavaScript, with a proven ability to integrate data from back-end services and relational databases. Skilled in collaborating with design teams to create seamless user interfaces and enduring optimal performance, security and scalability of websites.</p>
            </article>
        </section>        
     );
}

export default Welcome;

